#root {
  display: flex;
  width: 100%;
  height: 100%; }

.App {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center; }
