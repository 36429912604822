:root {
	--h-spacing: 4svw;
	--v-spacing: 4svw;
}

@media (max-width: 1200px) {
	:root {
		--h-spacing: 2vw;
	}
}

html,
body {
	font-size: 17px;
}

@media (max-width: 1200px) {
	html,
	body {
		font-size: 15px;
	}
}

@media (max-width: 860px) {
	html,
	body {
		font-size: 13px;
	}
}
