// @import '~in-context'

@keyframes reveal {
	0% {
		opacity: 0;
		transform: translateY(1em);
 }		// transform: translate(-50%, calc(-50% + 50px))

	100% {
		opacity: 1;
		transform: translateY(0);
 } }		// transform: translate(-50%, -50%)


h1.Masthead {
	// PRAH:
	// text-shadow: 6px 0px 0px red
	// -webkit-text-stroke-width: 2px;
	// -webkit-text-stroke-color: #00F;
	// color: yellow
	// text-transform: uppercase
	color: #ddd;
	text-shadow: 0px 3px 2px rgba(0, 0, 0, .25);
	z-index: 1000;
	margin: 0;
	pointer-events: none;

	animation: reveal 500ms ease-out 300ms;
	animation-fill-mode: backwards;

	font-size: 2.5rem;
	letter-spacing: 0.4em;

	@media (orientation: landscape) {
		display: flex;

		width: 100%;
		height: 35%;

		align-items: flex-end;
		justify-content: center;
		padding-bottom: calc(var(--v-spacing)); }

	@media (orientation: portrait) {
		display: flex;

		width: 100%;
		height: 20%;

		align-items: flex-end;
		justify-content: center;
		padding-bottom: calc(var(--v-spacing)*2);

		@media (max-width: 768px) {
			font-size: 1.75rem; } } }
