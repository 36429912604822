body {
  background: black; }

.Scenery {
  position: relative;
  width: 100svw;
  height: 100svh;
  overflow: hidden;

  .noise {
    position: absolute;
    width: 100svw;
    height: 100svh;
    pointer-events: none;
    background: url(/noise.gif);
    z-index: 25;
    mix-blend-mode: overlay;
    opacity: 0.125; }

  .background {
    width: 100svw;
    height: 100svh;
    overflow: hidden;
    z-index: 10;

    img {
      display: block;
      position: absolute;

      width: 100vw;
      width: 100svw;
      height: 100vh;
      height: 100svh;
      top: 0;
      left: 0;

      // transform: translate(-50%, -50%)

      pointer-events: none;
      object-fit: cover;

      &.hidden {
        display: none;
        opacity: 0; }

      &.next {
        opacity: 0;

        z-index: 3; }

      &.current {
        opacity: 1;
        transform: scale(1);
        transition: opacity 200ms linear 0ms, transform 300ms ease-out 0ms;
        z-index: 2; }

      &.previous {
        opacity: 0;
        transition: opacity 1ms linear 200ms;
        z-index: 1; } } }

  &.isWalking {
    .background {
      img {
        &.current {
          transition: opacity 200ms linear 0ms, transform 15s linear 0ms; }

        &.next {
          transform: scale(1.2); } } } }


  .foreground {
    position: absolute;
    width: 100svw;
    height: 100svh;
    overflow: hidden;
    z-index: 20;
    display: flex;
    flex-wrap: wrap; } }
