@import '~in-context';

$small-width: 440px;
$small-height-se: 667px;

@keyframes arrive {
	0% {
		opacity: 0;
		transform: translateY(1em); }

	100% {
		opacity: 1;
		transform: translateY(0); } }

.Player {
	@media (orientation: landscape) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		width: 50%;
		height: 65%;

		padding-top: calc(var(--v-spacing));
		padding-left: calc(var(--h-spacing));
		padding-right: calc(var(--h-spacing) * 2);

		gap: calc(var(--v-spacing) * 0.5); }

	@media (orientation: portrait) {
		order: 2;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;

		width: 100%;
		height: 40%;

		gap: calc(var(--v-spacing) * 0.5); }

	.track {
		position: relative; }

	.tracks {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
		width: 100%;
		max-width: 24em; }

	button {
		font-size: 1.25rem;
		line-height: 1.5rem;
		background: transparent;
		text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.33);
		border: none;
		text-align: left;
		transition: color 200ms;
		cursor: pointer;
		color: #eee;
		padding: 0.3em 0;
		letter-spacing: 0.9em;
		letter-spacing: 0.3em;
		animation: arrive 500ms ease-out;
		animation-fill-mode: backwards;
		// text-align: center
		display: flex;

		// width: 100%
		justify-content: space-between;

		.title {
			flex-shrink: 0; }

		.delimiter {
			display: inline-block;
			margin-left: 1em;
			margin-right: 1em;
			margin-left: 0.5em;
			min-width: 0.5em;
			overflow: hidden;
			flex-grow: 1;
			flex-shrink: 1; }

		.duration {
			flex-shrink: 0; } }

	@for $i from 1 through 20 {
		button:nth-child(#{$i}) {
			animation-delay: #{$i * 75ms + 600ms}; } }

	button, button:hover, button:active, button:link, button:visited, button:focus {
		outline: none; }

	@media (orientation: landscape) {
		button:hover {
			color: mix(yellow, orange, 50%); } }

	button.playing, button.playing:hover {
		color: #00e2a4;
		transition: color 800ms; }

	button.loading, button.loading:hover {
		color: orange;
		transition: color 800ms; }

	button.stop {
		margin-top: 3rem;
		margin-left: 0.5rem;
		font-size: 1.5rem;

		&.isPlaying-true {
			opacity: 1; }

		&.isPlaying-false {
			opacity: 0; } }

	audio {
		display: none;
		margin-top: 3rem;
		width: 100%; }

	.copy {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-family: sans-serif;
		font-size: 0.8rem;
		width: 100%;
		max-width: 24rem;
		text-align: center;

		animation: arrive 500ms ease-out;
		animation-fill-mode: backwards;
		animation-delay: 1.5s;

		@include in-context(small only) {
			font-size: 0.9rem; }

		&, & a {
			color: #888; } } }

.ProgressBar {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	background: rgba(0, 0, 0, 0.33);
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	pointer-events: none;
	z-index: 9999999;

	hr {
		border: 1.5px solid #00e2a4;
		margin: 0;
		transition: width 256ms linear; } }
