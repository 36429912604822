@import '~in-context';

$width: 20rem;
$anchor: 25svw;
$walk-speed: 3;
$step-height: 1/8;

@keyframes walk {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(.5%); } }

@keyframes blink {

  0% {
    width: 4%;
    border-radius: 100%;
    top: 24%;
    height: 2.5%; }

  48% {
    width: 4%;
    border-radius: 100%;
    top: 24%;
    height: 2.5%; }

  50% {
    width: 6%;
    border-radius: 0%;
    top: 25%;
    height: 4px; }

  52% {
    width: 4%;
    border-radius: 100%;
    top: 24%;
    height: 2.5%; }

  100% {
    width: 4%;
    border-radius: 100%;
    top: 24%;
    height: 2.5%; } }

.Me {
  pointer-events: none;

  @media (orientation: landscape) {
    display: flex;

    width: 50%;
    height: 65%;

    align-items: flex-end;
    justify-content: flex-end;

    padding-top: calc(var(--v-spacing));
    padding-right: calc(var(--h-spacing));
    padding-left: calc(var(--h-spacing) * 2);

    img {
      height: 65svh;
      width: auto; } }

  @media (orientation: portrait) {
    order: 3;

    display: flex;

    width: 100%;
    height: 40%;

    align-items: flex-end;
    justify-content: center;

    img {
      height: 40svh;
      width: auto; } }


  .container {
    position: relative;
    display: flex; }

  .eye {
    position: absolute;
    width: 6%;
    height: 4px;
    border-radius: 0;
    background: black;
    top: 26%; }

  .eye.left {
    left: 36%; }

  .eye.right {
    right: 36%; }

  &.isWalking {
    animation: walk (1s / $walk-speed) ease-in-out infinite alternate;

    .eye {
        width: 4%;
        top: 24%;
        border-radius: 100%;
        height: 2.5%;
        animation: blink 10s;
        animation-timing-function: steps(1);
        animation-iteration-count: infinite; }

    .eye.left {
      left: 35%; }

    .eye.right {
      right: 35%; } }

  img.loading {
    opacity: 0; }

  img.loaded {
    opacity: 1;
    transition: opacity 200ms linear; } }
